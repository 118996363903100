








































































import { Table, TableColumn } from 'element-ui';
import { ContainerMixin } from '@/common/mixin/containerMixin';
import { getCurationGroups } from '@/domain/curation/graphqls/curationList';
import { ListingCurationGroup } from '@/domain/curation/models/curation';

export default ContainerMixin.extend({
  name: 'curation-group-list',
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
  },
  methods: {},
  data(): {
    productContainer: ListingCurationGroup[];
  } {
    return {
      productContainer: [],
    };
  },
  apollo: {
    productContainer: {
      query: getCurationGroups,
    },
  },
});
