








import Vue from 'vue';
import CurationGroupList from '../components/CurationGroupList.vue';

export default Vue.extend({
  name: 'CurationGroupContainer',
  components: { CurationGroupList },
  methods: {
    onRefresh() {
      (this.$refs.list as any).refresh();
    },
  },
});
