import { render, staticRenderFns } from "./CurationGroupContainer.vue?vue&type=template&id=74f6e675&scoped=true&"
import script from "./CurationGroupContainer.vue?vue&type=script&lang=ts&"
export * from "./CurationGroupContainer.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74f6e675",
  null
  
)

export default component.exports